import BoardsSummaryStats from '@/shared/components/advertisement-info/BoardsSummaryStats';
import { IFullAdvertisementModel } from '@/shared/models/advertisements/full-advertisement-model';
import NoData from '@/statistics/templates/shared/NoData';
import { useAppSelector } from '@/store/hooks/redux';
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import SectionHeader from '../SectionHeader';
import DatesBreakDown from '../DatesBreakdown';
import { useTranslation } from 'react-i18next';
import BoardsPreviewSummary from '@/shared/components/platform-previews/previews/boards/BoardsPreviewSummary';

export interface IEieBoardsSectionProps {
  advertisement: IFullAdvertisementModel;
  extCompanyId: string;
}

const EieBoardsSection: FC<IEieBoardsSectionProps> = (props: IEieBoardsSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    advertisement: { advertisementStats },
    extCompanyId,
  } = props;

  const creativeFormsState = useAppSelector((state) => state.creativeStepSliceReducer.creativeFormsState);
  const currentCreativeForm = creativeFormsState['boards']['single'];

  return (
    <div>
      <Card sx={{ width: '100%' }}>
        <SectionHeader
          platform={'boards'}
          totalClicks={advertisementStats?.doohStats?.totalClicks ?? 0}
          totalImpressions={advertisementStats?.doohStats?.totalImpressions ?? 0}
        />
        <CardContent>
          <Grid container>
            <Grid item xs={12} md={5}>
              <BoardsPreviewSummary externalCompanyId={extCompanyId} values={currentCreativeForm!} />
            </Grid>
            {advertisementStats.doohStats?.totalClicks! > 0 ? (
              <Grid item xs={12} md={6} mx={'auto'}>
                <DatesBreakDown datesBreakdown={advertisementStats?.doohStats?.datesBreakdown!} />
                <Divider sx={{ my: 2 }} />
                <BoardsSummaryStats doohStats={advertisementStats?.doohStats!} />
              </Grid>
            ) : (
              <Grid item xs={12} m={2} md={6} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Typography m={2} variant="h2">
                  {t('adWithNoStats')}
                </Typography>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
};

export default EieBoardsSection;
