import { Divider, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import availablePlatforms from '@/editor/shared/constants/available-platforms';
import { useTranslation } from 'react-i18next';

export interface ISectionHeaderProps {
  totalClicks: number;
  totalImpressions: number;
  platform: string;
}

const SectionHeader: FC<ISectionHeaderProps> = (props: ISectionHeaderProps): JSX.Element => {
  const { totalClicks, totalImpressions, platform } = props;

  const findIcon = (platform: string) => {
    const Icon = availablePlatforms.find((el) => el.title === platform)?.icon;
    if (!Icon) return;
    return <Icon fontSize={'large'}></Icon>;
  };
  const { t } = useTranslation()

  return (
    <Grid container p={2}>
      <Divider sx={{ width: '100%' }} />
      <Grid item p={2} xs={7} md={10} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography fontWeight={300} display={'flex'} alignItems={'center'} variant="h3">
          {findIcon(platform)}
          {t(platform)}
        </Typography>
      </Grid>
      <Grid item p={2} xs={5} md={2} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography fontWeight={300} variant="h4" display={'flex'} alignItems={'center'}>
          <VisibilityIcon sx={{ mr: 0.5 }} /> {totalImpressions}
        </Typography>
        <Typography fontWeight={300} variant="h4" display={'flex'} alignItems={'center'}>
          <AdsClickIcon sx={{ mr: 0.5 }} /> {totalClicks}
        </Typography>
      </Grid>
      <Divider sx={{ width: '100%' }} />
    </Grid>
  );
};

export default SectionHeader;
