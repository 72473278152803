import { FC } from 'react';
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import CommonTextField from '../../../../../shared/components/CommonTextField';
import { useFormikContext } from 'formik';
import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { useTranslation } from 'react-i18next';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';

const AdSetPartialForm: FC<{ updating: boolean; budgets: IBudgetModel[] }> = (props: {
  updating: boolean;
  budgets: IBudgetModel[];
}): JSX.Element => {
  const { updating, budgets } = props;
  const { setFieldValue, getFieldMeta, handleChange } = useFormikContext();
  const { t } = useTranslation();
  const handleCustomChange = (e: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    localStorage.removeItem(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS);
    handleChange(e);
  };

  return (
    <div>
      <Box component="div" sx={{ mt: '15px' }}>
        <DesktopDatePicker
          label={t('startDate')}
          inputFormat="DD-MM-YYYY"
          disabled={updating}
          value={getFieldMeta('startTime').value}
          onChange={(e) => {
            setFieldValue('startTime', e);
          }}
          renderInput={(params) => (
            <CommonTextField
              {...params}
              error={Boolean(getFieldMeta('startTime').touched && getFieldMeta('startTime').error)}
              helperText={Boolean(getFieldMeta('startTime').touched && getFieldMeta('startTime').error)}
            />
          )}
        />
      </Box>
      <Box component="div" sx={{ mt: '15px' }}>
        <DesktopDatePicker
          disabled={updating}
          label={t('endDate')}
          inputFormat="DD-MM-YYYY"
          value={getFieldMeta('endTime').value}
          onChange={(e) => {
            setFieldValue('endTime', e);
          }}
          renderInput={(params) => (
            <CommonTextField
              {...params}
              error={Boolean(getFieldMeta('endTime').touched && getFieldMeta('endTime').error)}
              helperText={Boolean(getFieldMeta('endTime').touched && getFieldMeta('endTime').error)}
            />
          )}
        />
      </Box>
      {updating ? (
        ''
      ) : (
        <FormControl disabled={updating} fullWidth sx={{ marginTop: '15px' }}>
          <InputLabel id="budget_input_label_id">{t('budget')}</InputLabel>
          <Select
            color="secondary"
            name="budgetId"
            labelId="budget_input_label_id"
            id="budget_input_id"
            value={(getFieldMeta('budgetId').value as string) ?? ''}
            onChange={handleCustomChange as any}
            input={<OutlinedInput label="Budget"></OutlinedInput>}
            renderValue={(value: string) => <Box component="div">{budgets?.find((el) => el.id === value)?.title}</Box>}>
            {budgets?.map(({ title, id }) => (
              <MenuItem key={id} value={id}>
                {title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};
export default AdSetPartialForm;
