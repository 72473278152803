import { IBasicBreakdownModel } from '@/shared/models/advertisements/statistics/meta-stats.model';
import { IBaseChartsProps } from '@/statistics/components/chart-props.moldel';
import LineChart from '@/statistics/components/LineChart';
import { AdsClickOutlined } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import { Box, Grid, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export interface IDatesBreakDownProps {
  datesBreakdown: IBasicBreakdownModel[];
}

const DatesBreakDown: FC<IDatesBreakDownProps> = ({ datesBreakdown }): JSX.Element => {
  const { t } = useTranslation();

  const getDataFroLineChart = () => {
    const lineChartData: IBaseChartsProps = {
      labels: datesBreakdown.map((el) => el.key) ?? [],
      dataSets: [
        {
          label: t('clicks'),
          data: datesBreakdown.map((el) => el.clicks) ?? [],
          borderColor: '#162831',
          backgroundColor: '#162831',
        },
        {
          label: t('impressions'),
          data: datesBreakdown.map((el) => el.impressions) ?? [],
          borderColor: '#ffa737',
          backgroundColor: '#ffa737',
        },
      ],
    };
    return lineChartData;
  };

  const calculateTotalClicksByDatesBreakDown = () => {
    return datesBreakdown?.reduce((acc, curr) => {
      acc += curr.clicks;
      return acc;
    }, 0);
  };

  const calculateTotalImpressionsByDatesBreakDown = () => {
    return datesBreakdown?.reduce((acc, curr) => {
      acc += curr.impressions;
      return acc;
    }, 0);
  };

  return (
    <div>
      {datesBreakdown.length ? (
        <Grid container item mt={2}>
          <Grid
            item
            xs={12}
            md={2}
            my={{ xs: 2, md: 8 }}
            display={'flex'}
            justifyContent={'space-around'}
            flexDirection={{ md: 'column' }}>
            <Box display={'flex'} alignItems={'center'}>
              <Visibility sx={{ fontSize: 25, mr: 0.5 }} />
              <Typography variant="h5">{calculateTotalImpressionsByDatesBreakDown()}</Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
              <AdsClickOutlined sx={{ fontSize: 25, mr: 0.5 }} />
              <Typography variant="h5">{calculateTotalClicksByDatesBreakDown()}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={10}>
            <LineChart {...getDataFroLineChart()} />
          </Grid>
        </Grid>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default DatesBreakDown;
