import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { buildYupTimeDateProperty } from '@/shared/services/utils/moment-utils';
import { Box, Button, ButtonGroup, Card, CardContent, Grid, Typography } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import CommonButton from '../../../../../shared/components/CommonButton';
import ConfiguredGoogleMap from './ConfiguredGoogleMap';
import AdSetPartialForm from './AdSetPartialForm';
import CampaignPartialForm from './CampaignPartialForm';
import SelectPlatforms from './SelectPlatforms';
import { useTranslation } from 'react-i18next';
import CreativeTypesEnum, { CreativeType } from '@/editor/shared/constants/creatives-types.enum';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { setSelectedCreativeType } from '@/editor/store/reducers/creative-step.slice';
import { ArrowForward, SendOutlined } from '@mui/icons-material';
import { ICircleStoreModel, setAdSetStepValues, setAdsetValuesUpdated } from '@/editor/store/reducers/adSet-step.slice';
import moment from 'moment';

export interface IAdSettingsFormValues {
  title: string;
  places: ICircleStoreModel[];
  startTime: string;
  endTime: string;
  budgetId: string;
}

export interface IAdSettingsFormProps {
  budgets: IBudgetModel[];
  actionLoading: boolean;
  updating?: boolean;
  initialPlaces?: ICircleStoreModel[] | null;
  onSubmitForm: (values: IAdSettingsFormValues) => void;
  initialValues: IAdSettingsFormValues;

  handleBackBtnClicked?(): void;
}

const buttons = [
  { type: CreativeTypesEnum.SINGLE, text: 'standard' },
  { type: CreativeTypesEnum.DYNAMIC, text: 'abTest' },
  { type: CreativeTypesEnum.CAROUSEL, text: 'carousel' },
  { type: CreativeTypesEnum.VIDEO, text: 'video' },
];

const AdSettingsForm: React.FC<IAdSettingsFormProps> = ({
  budgets,
  actionLoading,
  initialValues,
  onSubmitForm,
  updating = false,
}): JSX.Element => {
  const { t } = useTranslation();
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const dispatch = useAppDispatch();
  const storedAdSetValues = useAppSelector((state) => state.adSetStepSliceReducer);

  useEffect(() => {
    dispatch(
      setAdSetStepValues({
        adSet: getAdsetPartial(initialValues),
        circles: initialValues.places,
        valuesUpdated: false,
        valuesFetchedFromLocalStorage: true,
      })
    );
    formik.setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (storedAdSetValues.valuesUpdated && !updating) {
      initialValues = { ...storedAdSetValues.adSet, places: storedAdSetValues.places };
      formik.setValues(initialValues);
      dispatch(setAdsetValuesUpdated(false));
    }
  }, [initialValues, storedAdSetValues.valuesUpdated]);

  useEffect(() => {
    if (storedAdSetValues.places.length > 0) {
      formik.setFieldValue('places', storedAdSetValues.places);
    }
  }, [storedAdSetValues.places]);

  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: yup.object().shape({
      title: yup.string().required(t('Title is required')),
      places: yup.array().required(t('Places is required')).min(1),
      startTime: buildYupTimeDateProperty(t('Start time'), true, false, 0, 0),
      endTime: buildYupTimeDateProperty(t('End time'), false, true, 7, 0),
      budgetId: yup.string().required(t('Budget is required')),
    }),
    onSubmit: async (values: IAdSettingsFormValues) => {
      onSubmitForm(values);
      if (formik.isValid) {
        dispatch(
          setAdSetStepValues({
            adSet: getAdsetPartial(values),
            circles: formik.values.places,
            valuesUpdated: true,
            valuesFetchedFromLocalStorage: true,
          })
        );
      }
    },
  });

  useEffect(() => {
    if (formik.isValid && !updating) {
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.ADSET_FORM, JSON.stringify(formik.values));
    }
  }, [formik.values]);

  const handleSetTypeOfCreative = (creativeType: CreativeType) => {
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.SELECTED_CREATIVE_TYPE, creativeType);
    dispatch(setSelectedCreativeType({ creativeType }));
  };

  const getAdsetPartial = (
    values: IAdSettingsFormValues
  ): Pick<IAdSettingsFormValues, 'title' | 'startTime' | 'endTime' | 'budgetId'> => {
    return {
      title: values.title,
      startTime: moment(values.startTime).format('YYYY-MM-DD'),
      endTime: moment(values.endTime).format('YYYY-MM-DD'),
      budgetId: values.budgetId,
    };
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <form
          noValidate
          onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
          onSubmit={formik.handleSubmit}>
          <Grid container sx={{ p: 1 }} spacing={2}>
            <Grid item xs={12} md={6}>
              <Card sx={{ width: '100%' }}>
                <CardContent>
                  <Grid container spacing={{ xs: 3, sm: 3 }}>
                    {!updating ? (
                      <Grid item xs={12}>
                        <Box>
                          <Typography variant="h6" color="secondary">
                            {t('selectAdType')}
                          </Typography>
                          <ButtonGroup variant="contained" sx={{ mt: '10px' }} color="secondary" size="medium">
                            {buttons.map((el) => (
                              <Button
                                className={selectedCreativeType === el.type ? 'btn-selected' : ''}
                                onClick={() => handleSetTypeOfCreative(el.type)}
                                key={el.type}>
                                {t(el.text)}
                              </Button>
                            ))}
                          </ButtonGroup>
                        </Box>
                      </Grid>
                    ) : (
                      <div></div>
                    )}
                    <Grid item xs={12}>
                      <CampaignPartialForm />
                      <AdSetPartialForm updating={updating} budgets={budgets} />
                      <SelectPlatforms
                        updating={updating}
                        selectedBudget={budgets?.find((el) => el.id === formik.getFieldMeta('budgetId').value)!}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card sx={{ width: '100%', minHeight: '610px' }}>
                <CardContent>
                  <Grid container sx={{ minHeight: '500px' }}>
                    <Grid item xs={12}>
                      <ConfiguredGoogleMap />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {!updating ? (
                <Card sx={{ backgroundColor: '#FFF', display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                  <CommonButton
                    size="small"
                    type="submit"
                    disabled={actionLoading}
                    endIcon={<ArrowForward fontSize="small" />}>
                    {t('submit')}
                  </CommonButton>
                </Card>
              ) : (
                <Card sx={{ boxShadow: 'none', display: 'flex', justifyContent: 'flex-end', p: 1 }}>
                  <CommonButton
                    size="small"
                    type="submit"
                    disabled={actionLoading}
                    endIcon={<SendOutlined fontSize="small" />}>
                    {t('update')}
                  </CommonButton>
                </Card>
              )}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </div>
  );
};

export default AdSettingsForm;
