import { setPropertEstateType } from '@/editor/store/reducers/property-info.slice';
import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import Loader from '../../../shared/components/Loader';
import { getValueFromLs, setKeyValuePairToLS } from '@/shared/services/localstorage';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { useGetRetailPropertyDataQuery } from '../../services/http/retail-data-provider.api';
import { EDITOR_LS_CONSTANTS } from '../../shared/constants/editor-LS.constants';
import { refreshCreativeFormState } from '../../store/reducers/creative-step.slice';
import StepResolver from './components/StepResolver';
import StepStack from './components/stepper/StepStack';

export default function CreateAdsStepsPage() {
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const propertyExtId = useAppSelector((state) => state.propertyInfoSliceReducer.propertyExtId);
  const companyId = useAppSelector((state) => state.propertyInfoSliceReducer.companyId);
  const currentStep = getValueFromLs(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP);

  const { currentData, isLoading, isFetching, refetch, isUninitialized } = useGetRetailPropertyDataQuery(
    { propertyId: propertyExtId ?? '', companyId: companyId ?? '' },
    { skip: !propertyExtId }
  );

  useEffect(() => {
    if (currentStep) {
      setActiveStep(parseInt(currentStep));
    }
  }, [currentStep]);

  useEffect(() => {
    if (!isUninitialized) {
      refetch();
    }
  }, [propertyExtId]);

  useEffect(() => {
    if (currentStep) {
      setActiveStep(parseInt(currentStep));
    }

    return () => {
      dispatch(refreshCreativeFormState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentData) {
      dispatch(setPropertEstateType(currentData?.propertyEstateType || ''));
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.PROPERTY_ESTATE_TYPE, currentData?.propertyEstateType || '');
    }
  }, [currentData, dispatch]);

  const handleActiveStepChanged = (step: number) => {
    setActiveStep(step);
    setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP, step.toString());
  };

  const handleBackBtnClicked = () => {
    if (activeStep !== 0) {
      setActiveStep((prevState) => prevState - 1);
      // I don't know why but this is not working with just activeStep, you need to store active step-1 like something is wrong with rerendering
      setKeyValuePairToLS(EDITOR_LS_CONSTANTS.CREATE_ORDER_ACTIVE_STEP, (activeStep - 1).toString());
    }
  };

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <Box width={'100vw'} p={'8px 0'} alignSelf={'flex-start'} sx={{ backgroundColor: '#fff' }}>
            <StepStack activeStep={activeStep} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isLoading || isFetching ? (
            <Loader />
          ) : (
            <StepResolver
              activeStep={activeStep}
              handleBackBtnClicked={handleBackBtnClicked}
              handleChangeActiveStep={handleActiveStepChanged}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
