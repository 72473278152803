import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from '@/store/hooks/redux';
import MediaLibraryDrawer from '../components/media-library/MediaLibraryDrawer';
import FinishingCreationOfAd from '../shared/components/modals/FinishingCreationOfAd';
import ImageCropper from '../shared/components/modals/ImageCropper';
import LeaveAdCreativeStepsModal from '../shared/components/modals/LeaveAdCreativeStepsModal';
import SideBar from './SideBar';
import { IAuthResponse } from '@/auth/models/auth.response';
import { getCredentialsFromLS } from '@/shared/services/localstorage';
import { useGetMeQuery } from '../services/http/users.api';
import useAppInitLanguage from '@/shared/hooks/app-init-hook';

const EditorLayout: React.FC = (): JSX.Element => {
  // const { collapseSidebar, collapsed, broken, toggleSidebar } = useProSidebar();
  // const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  // const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  //
  // const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElNav(event.currentTarget);
  // };
  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget);
  // };
  //
  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };
  //
  // const handleCloseUserMenu = () => {
  //   setAnchorElUser(null);
  // };

  const credentials: IAuthResponse | null = getCredentialsFromLS();
  const { data: userData, isSuccess: userDataSuccess } = useGetMeQuery(undefined, { skip: !credentials });
  useAppInitLanguage(userDataSuccess);

  const modalStates = useAppSelector((state) => state.editorModalsDrawersReducer);

  return (
    <Box component="div" sx={{ height: '100%', width: '100%' }}>
      <Box
        component="div"
        sx={{
          display: 'flex',
          height: '100vh',
          top: 0,
          bottom: 0,
          zIndex: 10000,
        }}>
        <SideBar></SideBar>
        <Box sx={{ width: '100%', overflowY: 'scroll' }}>
          <Container disableGutters={true} maxWidth={false}>
            <Outlet />
          </Container>
        </Box>
      </Box>
      <MediaLibraryDrawer />

      {modalStates.leaveAdCreationSteps.isOpened && <LeaveAdCreativeStepsModal />}
      {modalStates.finishingCreationOfAd && <FinishingCreationOfAd />}
      {modalStates.imageCropper.isOpened && <ImageCropper />}
    </Box>
  );
};

export default EditorLayout;
