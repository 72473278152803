import { useAppSelector } from '@/store/hooks/redux';
import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import ExternalCompanyIdEnum from '@/shared/constants/external-company-id.enum';
import { IExtendedPreviewPropsModel } from '@/editor/shared/components/forms/creatives/models/basic-preview-props.model';
const NativeAdPreview: React.FC<IExtendedPreviewPropsModel> = ({
  caption,
  viewDate,
  companyExternalId,
}): JSX.Element => {
  const media = useAppSelector(
    (state) => state.creativeStepSliceReducer?.creativeFormsState?.bidtheatre?.single?.media
  );
  return (
    <div>
      <Grid container sx={{ border: '1px solid #231464', maxWidth: '700px', height: 'auto', overflow: 'hidden' }}>
        <Grid item xs={12} sx={{ minHeight: '325px', pb: 4, backgroundColor: '#FFF' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}>
            <div>
              <Typography
                sx={{
                  color: `${
                    companyExternalId === ExternalCompanyIdEnum.MSPECS
                      ? '#255a88'
                      : companyExternalId === ExternalCompanyIdEnum.SORMEGLEREN
                      ? '#231464'
                      : companyExternalId === ExternalCompanyIdEnum.SMG
                      ? '#859419'
                      : companyExternalId === ExternalCompanyIdEnum.FREMEIENDOMSMEGLING
                      ? 'rgb(244, 125, 75)'
                      : '#000'
                  }`,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '350px',
                  height: '24px',
                }}
                variant="h5"
                fontFamily="GothamNarrowBook">
                <span>{viewDate && <div>{viewDate}</div>}</span>
              </Typography>
              <Typography
                sx={{
                  color: `${
                    companyExternalId === ExternalCompanyIdEnum.MSPECS
                      ? '#255a88'
                      : companyExternalId === ExternalCompanyIdEnum.SORMEGLEREN
                      ? '#231464'
                      : companyExternalId === ExternalCompanyIdEnum.SMG
                      ? '#859419'
                      : companyExternalId === ExternalCompanyIdEnum.FREMEIENDOMSMEGLING
                      ? 'rgb(244, 125, 75)'
                      : '#000'
                  }`,
                }}
                variant="h5"
                fontFamily="GothamNarrowBook">
                <span>ANNONSE</span>
              </Typography>
            </div>
          </Box>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              overflow: 'hidden',
            }}>
            <img
              className=""
              src={media?.preSignedUrl}
              alt="property"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Box>
          <Box
            sx={{
              float: 'left',
              position: 'relative',
              top: `${
                companyExternalId === ExternalCompanyIdEnum.EIE || companyExternalId === ExternalCompanyIdEnum.EIE_VISMA
                  ? '-64px'
                  : '-40px'
              }`,
              left: '17px',
            }}>
            <img
              style={{
                width: `${
                  companyExternalId === ExternalCompanyIdEnum.EIE ||
                  companyExternalId === ExternalCompanyIdEnum.EIE_VISMA
                    ? '55px'
                    : '100px'
                }`,
              }}
              src={`${
                companyExternalId === ExternalCompanyIdEnum.MSPECS
                  ? '/images/mspecs/mspecs_logo.png'
                  : companyExternalId === ExternalCompanyIdEnum.SMG
                  ? '/images/svenska/svenska_logo.png'
                  : companyExternalId === ExternalCompanyIdEnum.SORMEGLEREN
                  ? '/images/sormegleren/sormegleren_logo_a_rgb.png'
                  : companyExternalId === ExternalCompanyIdEnum.FREMEIENDOMSMEGLING
                  ? '/images/fremeiendomsmegling/Native_Frem_Eiendomsmegling.png'
                  : '/images/eie/native_eie_logo_1.png'
              }`}
              alt="sormegleren-logo"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ zIndex: 10 }} p={2}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              color: `${
                companyExternalId === ExternalCompanyIdEnum.MSPECS
                  ? '#255a88'
                  : companyExternalId === ExternalCompanyIdEnum.SMG
                  ? '#859419'
                  : companyExternalId === ExternalCompanyIdEnum.SORMEGLEREN
                  ? '#231464'
                  : companyExternalId === ExternalCompanyIdEnum.FREMEIENDOMSMEGLING
                  ? 'rgb(244, 125, 75)'
                  : '#000'
              }`,
            }}
            variant="h4"
            fontFamily="GothamNarrowMedium">
            <span>{caption}</span>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};
export default NativeAdPreview;
