import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import availablePlatforms, {
  AvailablePlatformsEnum,
  PlatformType,
} from '@/editor/shared/constants/available-platforms';
import { ISliderValuesPerPlatform, setSlidersInitialState } from '@/editor/store/reducers/selected-platforms.slice';
import { IBudgetModel } from '@/shared/models/budgets/budget.model';
import { useEffect, useState } from 'react';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { getValueFromLs } from '@/shared/services/localstorage';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';

interface IPlatformCalculationOnBudgetProps {
  selectedBudget: IBudgetModel;
}

const platformsBySelectedType = {
  [CreativeTypesEnum.SINGLE]: [
    AvailablePlatformsEnum.META,
    AvailablePlatformsEnum.SNAPCHAT,
    AvailablePlatformsEnum.DELTA,
    AvailablePlatformsEnum.BIDTHEATRE,
    AvailablePlatformsEnum.BOARDS,
  ],
  [CreativeTypesEnum.DYNAMIC]: [
    AvailablePlatformsEnum.META,
    AvailablePlatformsEnum.SNAPCHAT,
    AvailablePlatformsEnum.BIDTHEATRE,
    AvailablePlatformsEnum.DELTA,
    AvailablePlatformsEnum.BOARDS,
  ],
  [CreativeTypesEnum.CAROUSEL]: [AvailablePlatformsEnum.META, AvailablePlatformsEnum.SNAPCHAT],
  [CreativeTypesEnum.VIDEO]: [AvailablePlatformsEnum.META],
};

const usePlatformCalculationOnBudget = (props: IPlatformCalculationOnBudgetProps) => {
  const { selectedBudget } = props;
  const dispatch = useAppDispatch();

  const [possibleSliders, setPossibleSliders] = useState<ISliderValuesPerPlatform[]>();
  const selectedCreativeType = useAppSelector((state) => state.creativeStepSliceReducer.selectedCreativeType);
  const slidersState = useAppSelector((state) => state.editorActivePlatformsReducer.slidersState);

  const findSliderValue = (platform: PlatformType): number => {
    if (slidersState.length === 0) {
      return selectedBudget.budgetItems.find((el) => el.type === platform)?.spreadBudgetPercentageDefault || 0;
    }

    let sliderValue = activePlatformsFromLS.includes(platform)
      ? slidersState.find((el) => el.platform === platform)?.value || 0
      : selectedBudget.budgetItems.find((el) => el.type === platform)?.spreadBudgetPercentageDefault || 0;

    return sliderValue as number;
  };

  const activePlatformsFromLS =
    getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) !== null
      ? JSON.parse(getValueFromLs(EDITOR_LS_CONSTANTS.ACTIVE_SOCIAL_MEDIA_PLATFORMS) as string)
      : [];

  useEffect(() => {
    const platforms = availablePlatforms.filter(({ id }) => {
      return (
        selectedBudget?.budgetItems?.filter((el) => el.type === id)?.length > 0 &&
        platformsBySelectedType[selectedCreativeType].includes(id as AvailablePlatformsEnum)
      );
    });

    if (!platforms) return;

    const sliderValuesPerPlatform = platforms.map((platform) => ({
      platform: platform.id,
      value: findSliderValue(platform.id),

      // if activePlatforms is = 0 that means that this is the initial run of the function
      isActive: platforms.length > activePlatformsFromLS.length ? true : activePlatformsFromLS.includes(platform.id),
    }));

    setPossibleSliders(sliderValuesPerPlatform);
    dispatch(setSlidersInitialState(sliderValuesPerPlatform));

    return () => setPossibleSliders([]);
  }, [selectedBudget, dispatch, selectedCreativeType]);

  return { possibleSliders };
};

export default usePlatformCalculationOnBudget;
