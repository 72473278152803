import {
  AvailablePlatformsEnum,
  PlatformType,
  platformsToCropImage,
} from '@/editor/shared/constants/available-platforms';
import CreativeTypesEnum from '@/editor/shared/constants/creatives-types.enum';
import { EDITOR_LS_CONSTANTS } from '@/editor/shared/constants/editor-LS.constants';
import { ICarouselCreativeItemFormValuesModel } from '@/editor/shared/components/forms/creatives/models/carousel-creative-form-values';
import {
  ICreativeFormsStatePerPlatform,
  addedMediaOnDynamic,
  removeMediaOnDynamic,
  removeMediaOnSingle,
  setMediaOnCarouselItem,
  setMediaOnSingle,
} from '@/editor/store/reducers/creative-step.slice';
import { setImageCropper } from '@/editor/store/reducers/modals-drawers.slice';
import EmptyImageCard from '@/shared/components/platform-previews/previews/EmptyImageCard';
import { IMediaLibraryImageModel } from '@/shared/models/media-library/media-library-image.model';
import { getValueFromLs } from '@/shared/services/localstorage';
import { useAppDispatch, useAppSelector } from '@/store/hooks/redux';
import { Box, ImageListItem, ImageListItemBar } from '@mui/material';
import React, { useCallback } from 'react';
import toast from 'react-hot-toast';
import FacebookIcon from '@mui/icons-material/Facebook';
import SnapChatIcon from '@/shared/icons/SnapChatIcon';
import NewspaperIcon from '@mui/icons-material/Newspaper';

export interface IMediaLibraryImageItemProps {
  item: IMediaLibraryImageModel;
  queue: number;
}

const MediaLibraryImageItem: React.FC<IMediaLibraryImageItemProps> = ({ item, queue }): JSX.Element => {
  const dispatch = useAppDispatch();
  const drawerState = useAppSelector((state) => state.editorModalsDrawersReducer.mediaLibraryDrawerState);
  const creativeState = useAppSelector((state) => state.creativeStepSliceReducer);

  const isImageSomeWhereSelected = (image: IMediaLibraryImageModel) => {
    const platformKeys = Object.values(AvailablePlatformsEnum);
    let found = false;
    for (let i = 0; i < platformKeys.length; i++) {
      if (found) break;

      if (
        platformKeys[i] === AvailablePlatformsEnum.DELTA ||
        platformKeys[i] === AvailablePlatformsEnum.BIDTHEATRE ||
        platformKeys[i] === AvailablePlatformsEnum.BOARDS
      ) {
        found = checkSingle(image, platformKeys[i]);
        continue;
      }

      if (
        checkSingle(image, platformKeys[i]) ||
        checkDynamic(image, platformKeys[i]) ||
        checkCarousel(image, platformKeys[i])
      ) {
        found = true;
        break;
      }
    }

    return found;
  };

  const checkSingle = (image: IMediaLibraryImageModel, platform: PlatformType): boolean => {
    return creativeState.creativeFormsState[platform][CreativeTypesEnum.SINGLE]?.media?.id === image.id;
  };

  const checkCarousel = (image: IMediaLibraryImageModel, platform: PlatformType): boolean => {
    return (
      creativeState.creativeFormsState[platform][CreativeTypesEnum.CAROUSEL]!.creativeItems.find(
        (el) => el.creativeMedia?.id === image.id
      ) !== undefined
    );
  };

  const checkDynamic = (image: IMediaLibraryImageModel, platform: PlatformType): boolean => {
    return (
      creativeState.creativeFormsState[platform][CreativeTypesEnum.DYNAMIC]?.creativeMedias.find(
        (el) => el.id === image.id
      ) !== undefined
    );
  };

  const handleImageClick = (platformOption: PlatformType, image: IMediaLibraryImageModel): void => {
    if (isImageSelectedByPlatform(platformOption, image)) {
      removeImage(platformOption, image);
      return;
    }

    if (ruleForDynamicAndCarousel(platformOption)) {
      toast.error('You can select only 5 images for dynamic and carousel creatives');
      return;
    }
    addingImage(platformOption, image);
  };

  const addingImage = (platformOption: string, image: IMediaLibraryImageModel): void => {
    const platform = platformOption as PlatformType;

    if (!checkDimensionsOfImage(platform, image)) {
      return;
    }

    const creativeType =
      platformOption === AvailablePlatformsEnum.DELTA ||
      platformOption === AvailablePlatformsEnum.BIDTHEATRE ||
      platformOption === AvailablePlatformsEnum.BOARDS
        ? CreativeTypesEnum.SINGLE
        : drawerState.creativeType;

    switch (creativeType) {
      case CreativeTypesEnum.CAROUSEL:
        updateCarouselItemImageIfNeeded(platform, image);
        break;
      case CreativeTypesEnum.DYNAMIC:
        dispatch(addedMediaOnDynamic({ media: image, platform }));
        break;
      case CreativeTypesEnum.SINGLE:
        dispatch(setMediaOnSingle({ media: image, platform }));
        break;
    }
  };

  const removeImage = (platformOption: string, image: IMediaLibraryImageModel): void => {
    const creativeType =
      platformOption === AvailablePlatformsEnum.DELTA ||
      platformOption === AvailablePlatformsEnum.BIDTHEATRE ||
      platformOption === AvailablePlatformsEnum.BOARDS
        ? CreativeTypesEnum.SINGLE
        : drawerState.creativeType;

    const platform = platformOption as PlatformType;
    switch (creativeType) {
      case CreativeTypesEnum.DYNAMIC:
        dispatch(removeMediaOnDynamic({ platform, media: image }));
        break;
      case CreativeTypesEnum.SINGLE:
        dispatch(removeMediaOnSingle({ platform }));
        break;
      case CreativeTypesEnum.CAROUSEL:
        updateCarouselItemImageIfNeeded(platform, null);
        break;
    }
  };

  const checkDimensionsOfImage = (platform: PlatformType, image: IMediaLibraryImageModel): boolean => {
    const platformConfigToCrop = platformsToCropImage.find((el) => el.platform === platform);

    if (
      platformConfigToCrop &&
      (image.width !== platformConfigToCrop.width || image.height !== platformConfigToCrop.height)
    ) {
      dispatch(
        setImageCropper({
          isOpened: true,
          image: image,
          platform: platform,
          propertyInternalId: getValueFromLs(EDITOR_LS_CONSTANTS.PROPERTY_INTERNAL_ID),
          widthToCrop: platformConfigToCrop.width,
          heightToCrop: platformConfigToCrop.height,
        })
      );

      return false;
    }

    return true;
  };

  const updateCarouselItemImageIfNeeded = (
    platformOption: PlatformType,
    media: IMediaLibraryImageModel | null
  ): void => {
    if (drawerState.carouselItemIndex !== undefined) {
      dispatch(
        setMediaOnCarouselItem({
          media,
          index: drawerState.carouselItemIndex,
          platform: platformOption as PlatformType,
        })
      );
    }
    if (media === null && drawerState.carouselItemIndex !== undefined) {
      toast.error('This images is already selected!');
    }
  };

  const ruleForDynamicAndCarousel = (platform: PlatformType): boolean => {
    const form = creativeState.creativeFormsState[platform] as ICreativeFormsStatePerPlatform;
    const medias: IMediaLibraryImageModel[] | ICarouselCreativeItemFormValuesModel[] =
      drawerState.creativeType === CreativeTypesEnum.DYNAMIC
        ? form.dynamic?.creativeMedias
        : form.carousel?.creativeItems;
    return medias?.length >= 5;
  };

  const isImageSelectedByPlatform = useCallback(
    (platform: PlatformType, image?: IMediaLibraryImageModel | null): boolean => {
      if (!image) {
        return false;
      }

      let creativeType =
        platform === AvailablePlatformsEnum.DELTA ||
        platform === AvailablePlatformsEnum.BIDTHEATRE ||
        platform === AvailablePlatformsEnum.BOARDS
          ? CreativeTypesEnum.SINGLE
          : drawerState.creativeType!;

      switch (creativeType) {
        case CreativeTypesEnum.DYNAMIC:
          return checkDynamic(image, platform);
        case CreativeTypesEnum.SINGLE:
          return checkSingle(image, platform);
        case CreativeTypesEnum.CAROUSEL:
          return checkCarousel(image, platform);
        default:
          return false;
      }
    },
    [creativeState.creativeFormsState, drawerState.creativeType]
  );

  return (
    <div>
      <Box
        onClick={() => handleImageClick(drawerState.platform!, item)}
        sx={{
          display: 'flex',
          position: 'relative',
          ':hover': {
            cursor: 'pointer',
          },
        }}>
        {isImageSomeWhereSelected(item) && (
          <Box
            sx={{
              position: 'absolute',
              zIndex: '2',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255,255,255,0.8)',
            }}>
            <Box
              sx={{
                position: 'absolute',
                zIndex: '2',
                top: '0',
                left: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              {isImageSelectedByPlatform(AvailablePlatformsEnum.META, item) && (
                <FacebookIcon sx={{ fontSize: '55px' }} />
              )}
              {isImageSelectedByPlatform(AvailablePlatformsEnum.SNAPCHAT, item) && (
                <SnapChatIcon fontSize={'x-large'} />
              )}
              {isImageSelectedByPlatform(AvailablePlatformsEnum.DELTA, item) && (
                <NewspaperIcon sx={{ fontSize: '55px' }} />
              )}
              {isImageSelectedByPlatform(AvailablePlatformsEnum.BIDTHEATRE, item) && (
                <img
                  src="/images/bidtheatre/bidtheatre.png"
                  alt="Bidtheatre"
                  style={{ fontSize: 'large', width: '24px', height: '24px' }}
                />
              )}
              {isImageSelectedByPlatform(AvailablePlatformsEnum.BOARDS, item) && (
                <img
                  src="/images/boards/boards_logo.jpg"
                  alt="Boards"
                  style={{ fontSize: 'large', width: '24px', height: '24px' }}
                />
              )}
            </Box>
          </Box>
        )}
        <ImageListItem sx={{ border: isImageSomeWhereSelected(item) ? '5px solid #000' : '5px solid transparent' }}>
          {item.preSignedUrl ? (
            <img src={item.preSignedUrl} srcSet={item.preSignedUrl} alt={item.fileName} loading="lazy" />
          ) : (
            <EmptyImageCard />
          )}
          <ImageListItemBar
            sx={{
              display: { xs: 'none', md: 'inline' },
              p: 0,
              '& .MuiImageListItemBar-titleWrap': {
                padding: '4px 8px',
              },
            }}
            title={queue + 1 + ' - ' + item.width + 'x' + item.height}></ImageListItemBar>
        </ImageListItem>
      </Box>
    </div>
  );
};

export default MediaLibraryImageItem;
