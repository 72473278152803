import React from 'react';
import { Box, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../../shared/components/Loader';
import { useGetAllProvidersQuery } from '@/admin/services/http/retail-data-provider.api';
import {
  useAddCompanyMutation,
  useEditCompanyMutation,
  useGetCompanyByIdQuery,
} from '@/admin/services/http/companies.api';
import CommonTextField from '../../../../shared/components/CommonTextField';
import CommonButton from '../../../../shared/components/CommonButton';
import { useTranslation } from 'react-i18next';

export interface ICreateEditCompaniesProps {
  isUpdating: boolean;
}

export interface ICompanyCreateEditFormValues {
  title: string;
  externalId?: string;
  retailDataProviderId?: string;
  locale: string;
}

const languageTable = [
  { value: 'en', title: 'english' },
  { value: 'sw', title: 'swedish' },
  { value: 'no', title: 'norwegian' },
];

const validationSchema = yup.object().shape({
  title: yup.string().required('Title is required'),
});

const CreateEditCompanyForm: React.FC<ICreateEditCompaniesProps> = ({ isUpdating }): JSX.Element => {
  const { id } = useParams();
  const [editCompany, { isLoading: editCompanyLoading }] = useEditCompanyMutation();
  const [addCompany, { isLoading: addCompanyLoading }] = useAddCompanyMutation();

  const {
    data: companyData,
    isLoading: companyDataLoading,
    isFetching: companyDataFetching,
  } = useGetCompanyByIdQuery({ id: id ?? '' }, { skip: !isUpdating });

  const { data: retailProviders } = useGetAllProvidersQuery();

  const { t } = useTranslation();

  const navigate = useNavigate();
  const initialValues: ICompanyCreateEditFormValues = {
    title: companyData?.title || '',
    externalId: companyData?.externalId || '',
    retailDataProviderId: companyData?.retailDataProvider?.id || '',
    locale: companyData?.locale || '',
  };

  const handleSubmit = async (values: ICompanyCreateEditFormValues) => {
    try {
      companyData ? await editCompany({ id: id!, values }).unwrap() : await addCompany(values).unwrap();
      navigate(-1);
    } catch (e) {
      return;
    }
  };

  return (
    <div>
      {!companyDataLoading && !companyDataFetching ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values: ICompanyCreateEditFormValues) => {
            await handleSubmit(values);
          }}>
          {({ errors, handleChange, handleSubmit, touched, values }) => (
            <form noValidate onSubmit={(e) => handleSubmit(e)}>
              <CommonTextField
                type="text"
                name="title"
                label={t('title')}
                value={values.title}
                onChange={handleChange}
                required
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
              />
              <CommonTextField
                sx={{ marginTop: '15px' }}
                type="text"
                name="externalId"
                label={t('externalId')}
                value={values.externalId}
                onChange={handleChange}
                required
                error={Boolean(touched.externalId && errors.externalId)}
                helperText={touched.externalId && errors.externalId}
              />
              <FormControl fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="locale_label_id">{t('languages')}</InputLabel>
                <Select
                  name="locale"
                  labelId="locale"
                  id="locale_input_id"
                  value={values.locale}
                  onChange={handleChange}
                  input={<OutlinedInput label="Languages"></OutlinedInput>}
                  renderValue={(value: string) => (
                    <Box component="div">{t(languageTable?.find((el) => el.value === value)?.title as string)}</Box>
                  )}>
                  {languageTable?.map(({ title, value }) => (
                    <MenuItem key={value} value={value}>
                      {t(title)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginTop: '15px' }}>
                <InputLabel id="provider_input_label_id">{t('languages')}</InputLabel>
                <Select
                  name="retailDataProviderId"
                  labelId="provider_input_label_id"
                  id="provider_input_id"
                  value={values.retailDataProviderId}
                  onChange={handleChange}
                  input={<OutlinedInput label={t('languages')}></OutlinedInput>}
                  renderValue={(value: string) => (
                    <Box component="div">{retailProviders?.find((el) => el.id === value)?.title}</Box>
                  )}>
                  {retailProviders?.map(({ title, id }) => (
                    <MenuItem key={id} value={id}>
                      {title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mt: 2,
                  }}>
                  <CommonButton type="submit" disabled={addCompanyLoading || editCompanyLoading}>
                    {id ? t('update') : t('create')}
                  </CommonButton>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CreateEditCompanyForm;
