import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@/store/store';
import { getCredentialsFromLS } from '../localstorage';

const listOfOpenEndpoints = ['connect'];

// Create our baseQuery instance
const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  prepareHeaders: (headers, { getState, endpoint }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests
    let token = (getState() as RootState).authReducer.accessToken;
    if (!token) {
      let credentials = getCredentialsFromLS();
      if (credentials) {
        token = credentials.accessToken;
      }
    }

    if (window.location.href.includes('connections')) {
      return;
    }

    if (token && !listOfOpenEndpoints.includes(endpoint)) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

// const baseQueryWithRetry = retry(baseQuery, { maxRetries: 3 });

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */
export const api = createApi({
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  // reducerPath: "adminApi",
  /**
   * A bare bones base query would just be `baseQuery: fetchBaseQuery({ baseUrl: '/' })`
   */
  baseQuery: baseQuery,
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [
    'Auth',
    'Users',
    'Roles',
    'RetailDataProviders',
    'UserProfiles',
    'Companies',
    'Offices',
    'AdminBudgets',
    'AdminBudgetItems',
    'Properties',
    'MediaLibraryImages',
    'CustomerCampaigns',
    'CustomerAdSets',
    'CustomerCreatives',
    'CustomerBudgets',
    'CustomersOrders',
    'CustomerAdvertisements',
    'CompanyPlatformsConfigs',
    'OfficePlatformsConfigs',
    'CompanyAutoCreationSettings',
    'CompanyBranding',
    'CustomerUser',
    'ApplicationLogs',
    'CustomerMoovly',
    'AdvertisementsAdmin',
    'Categories',
    'Medialists',
    'Audiences',
    'Filters',
  ],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({}),
});

export const TypeIdsDefinitions = Object.freeze({
  listTypeId: 'LIST',
  partialListTypeId: 'PARTIAL-LIST',
});
