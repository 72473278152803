import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { FC } from 'react';
import { IBidtheatreStatsEntryModel } from '../../models/advertisements/statistics/bidtheatre-stats-entry.model';
import { IBoardsStatsModel } from '../../models/advertisements/statistics/bidtheatre-stats.model';
import { useTranslation } from 'react-i18next';
import { AdsClick, Newspaper, Visibility } from '@mui/icons-material';

interface IBoardsSummaryStatsProps {
  doohStats: IBoardsStatsModel;
}

function createStatsValues(doohStats: IBoardsStatsModel) {
  const { regular, bySites } = doohStats;
  const res = [];

  if (regular?.length > 0) {
    res.push(...generateObject(regular));
  }

  if (bySites?.length > 0) {
    res.push(...generateObject(bySites));
  }

  return res;
}

function generateObject(stats: IBidtheatreStatsEntryModel[]) {
  return stats.map((el) => {
    const {
      name,
      entries: { clicks, impressions },
    } = el;

    return { name, clicks, impressions };
  });
}

const BoardsSummaryStats: FC<IBoardsSummaryStatsProps> = (props: IBoardsSummaryStatsProps): JSX.Element => {
  const { doohStats } = props;
  const stats = createStatsValues(doohStats);

  const { t } = useTranslation();

  return (
    <div>
      {(doohStats?.regular || doohStats.bySites) && (
        <TableContainer component={Paper} sx={{ maxHeight: '185px' }}>
          <Table stickyHeader size="small" aria-label="boards-table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#fff', backgroundColor: '#162831' }} align="left">
                  <Box display={'flex'} alignItems={'center'}>
                    <Newspaper sx={{ fontSize: '15px', mr: 0.5 }} />
                    {t('name')}
                  </Box>
                </TableCell>
                <TableCell sx={{ color: '#fff', backgroundColor: '#162831' }} align="left">
                  <Box display={'flex'} alignItems={'center'}>
                    <AdsClick sx={{ fontSize: '15px', mr: 0.5 }} />
                    {t('clicks')}
                  </Box>
                </TableCell>
                <TableCell sx={{ color: '#fff', backgroundColor: '#162831' }} align="left">
                  <Box display={'flex'} alignItems={'center'}>
                    <Visibility sx={{ fontSize: '15px', mr: 0.5 }} />
                    {t('impressions')}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.values(stats).map((row, index) => (
                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="left">{row.clicks}</TableCell>
                  <TableCell align="left">{row.impressions}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default BoardsSummaryStats;
