import { IAuthResponse } from '@/auth/models/auth.response';
import { setCredentials } from '@/auth/store/reducers/auth.slice';
import axiosInstance from '@/shared/services/api/axios.api';
import { substitutionOfCredentialsForImpersonationLS } from '@/shared/services/localstorage';
import { useAppDispatch } from '@/store/hooks/redux';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDataTableColumn } from '@/shared/components/DataTable';
import { debounce } from '@/shared/hooks/debounce';
import { IUserModel } from '@/shared/models/user.model';
import { ROUTES_DECLARATIONS } from '../../routes/routes';
import { ROUTE_USERS_DECLARATIONS } from '../../routes/users-routes';
import { useGetUsersPaginatedQuery } from '../../services/http/users.api';
import AdminTableWrapper from '../../shared/components/tables/AdminTableWrapper';
import { useTranslation } from 'react-i18next';
import PersonSearch from '@mui/icons-material/PersonSearch';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { AddCircle, Edit } from '@mui/icons-material';

const tableColumns = [
  {
    id: 'id',
    name: 'Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'email',
    name: 'Email',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'firstName',
    name: 'First name',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'lastName',
    name: 'Last name',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'officeId',
    name: 'Office Id',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'officeTitle',
    name: 'Office Title',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'companyTitle',
    name: 'Company Title',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'createdAt',
    name: 'Date of creation',
    enableSort: false,
    align: 'left',
  },
  {
    id: 'actions',
    name: 'Actions',
    enableSort: false,
    align: 'left',
  },
];

const UsersTable = (): JSX.Element => {
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(25);
  const [search, setSearch] = useState<string>('');
  const { data, isLoading } = useGetUsersPaginatedQuery({
    page,
    pageSize,
    search,
  });

  const { t } = useTranslation();

  tableColumns.forEach((col) => {
    if (col.name) {
      col.name = t(col.id);
    }
  });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleSearchChange = (value: string) => {
    debounce(setSearch)(value ?? '');
  };

  const tableHeaderActions = useMemo(() => {
    return [
      {
        title: t('create'),
        icon: <AddCircle />,
        action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/${ROUTE_USERS_DECLARATIONS.AdminUsersNewPage}`),
      },
    ];
  }, [navigate]);

  const impersonateUser = async (id: string) => {
    const res = await axiosInstance.post<IAuthResponse>('admin/impersonate', {
      userId: id,
    });

    substitutionOfCredentialsForImpersonationLS(res.data);
    dispatch(setCredentials(res.data));
    navigate('/editor?impersonation=True');
  };

  const mapUsers = (data?: IUserModel[]) => {
    return data
      ? data.map((el) => ({
          id: el.id,
          email: el.email,
          firstName: el.profile?.firstName,
          lastName: el.profile?.lastName,
          officeId: el.officeId,
          officeTitle: el.officeTitle,
          companyTitle: el.companyTitle,
          createdAt: el.createdAt,
          actions: [
            {
              title: t('details'),
              icon: <PersonSearch />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/users/${el.id}/user-profile`),
            },
            {
              title: t('edit'),
              icon: <Edit />,
              action: () => navigate(`${ROUTES_DECLARATIONS.AdminPanel}/users/${el.id}/edit`),
            },
            {
              title: t('impersonate'),
              icon: <SupervisedUserCircleIcon />,
              action: () => impersonateUser(el.id),
            },
          ],
        }))
      : [];
  };

  return (
    <div>
      <AdminTableWrapper
        header={t('users')}
        isLoading={isLoading}
        tableData={{
          columnData: tableColumns as IDataTableColumn[],
          rows: mapUsers(data?.items as IUserModel[]),
          totalRows: data?.totalCount!,
          handlePageChange: setPage,
          handlePageSizeChange: setPageSize,
        }}
        wrapperHeaderActions={{
          actions: tableHeaderActions,
          search: handleSearchChange,
        }}
      />
    </div>
  );
};

export default UsersTable;
